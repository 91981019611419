import { CHECK_CLIENT_IS_EXIST } from '@client/collections/Authentication/schemas/checkClientIsExist'
import { CHECK_CLIENT_IS_STUCK } from '@client/collections/Authentication/schemas/checkClientIsStuck'
import { LINK_WITH_LINE } from '@client/collections/Authentication/schemas/linkWithLine'
import { LINK_WITH_NAIIN } from '@client/collections/Authentication/schemas/linkWithNaiin'
import { LOGIN_WITH_LINE } from '@client/collections/Authentication/schemas/loginWithLine'
import { LOGIN_WITH_NAIIN } from '@client/collections/Authentication/schemas/loginWithNaiin'
import { UNLINK_LINE_PROVIDER } from '@client/collections/Authentication/schemas/unlinkLineProvider'
import { UNLINK_NAIIN_PROVIDER } from '@client/collections/Authentication/schemas/unlinkNaiinProvider'
import { gqlApiInstance } from '@client/init'

export function useAuthenticationAction() {
  async function loginWithLine(idToken: string): Promise<{ token: string }> {
    const res = await gqlApiInstance.request(LOGIN_WITH_LINE, {
      loginWithLineInput: { idToken },
    })

    return res.loginWithLine
  }

  async function linkWithLine(idToken: string): Promise<{ message: string }> {
    const res = await gqlApiInstance.request(LINK_WITH_LINE, {
      linkWithLineInput: { idToken },
    })
    return res.linkWithLine
  }

  async function unlinkLineProvider(): Promise<{ message: string }> {
    const res = await gqlApiInstance.request(UNLINK_LINE_PROVIDER)
    return res.unlinkLineProvider
  }

  async function loginWithNaiin(token: string): Promise<{ token: string }> {
    const res = await gqlApiInstance.request(LOGIN_WITH_NAIIN, {
      loginWithNaiinInput: { token },
    })
    return res.loginWithNaiin
  }

  async function linkWithNaiin(token: string): Promise<{ message: string }> {
    const res = await gqlApiInstance.request(LINK_WITH_NAIIN, {
      linkWithNaiinInput: { token },
    })
    return res.linkWithNaiin
  }

  async function unlinkNaiinProvider(): Promise<{ message: string }> {
    const res = await gqlApiInstance.request(UNLINK_NAIIN_PROVIDER)
    return res.unlinkNaiinProvider
  }

  async function checkClientIsStuck(email: string): Promise<boolean> {
    if (!email) return false

    const res = await gqlApiInstance.request(CHECK_CLIENT_IS_STUCK, { email })
    return res.checkClientIsStuck
  }

  async function checkClientIsExist(email: string): Promise<boolean> {
    const res = await gqlApiInstance.request(CHECK_CLIENT_IS_EXIST, { email })
    return res.checkClientIsExist
  }

  return {
    loginWithLine,
    linkWithLine,
    unlinkLineProvider,
    loginWithNaiin,
    linkWithNaiin,
    unlinkNaiinProvider,
    checkClientIsStuck,
    checkClientIsExist,
  }
}
