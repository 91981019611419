import { useContext, useEffect } from 'react'

import {
  useModal,
  ModalContext,
} from '@hooks/contexts/ModalContext/ModalContext'
import { useAuthentication } from '@hooks/useAuthentication'

export function UserManagement() {
  const { isAuthenticated, isUserLoading, isLogin } = useAuthentication()
  const loadingModal = useModal({ modal: 'loading' }, true)
  const { currentModal, isOpen } = useContext(ModalContext)

  useEffect(() => {
    if (isLogin && isUserLoading) {
      loadingModal.show()
    } else if (currentModal === 'loading' && isOpen) {
      loadingModal.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoading, isAuthenticated, isLogin])

  return null
}
